<template>
    <div>
        <!--搜索框-->
        <div style="display: flex;justify-content: flex-start;padding-top: 30px">
            <div>
                项目分类：
                <el-select v-model="project.projectTypeId" clearable placeholder="请选择项目分类">
                    <el-option
                            v-for="item in typeList"
                            :key="item.id"
                            :label="item.typeName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <div style="padding-left: 30px">
                项目状态：
                <el-select v-model="project.projectStatus" clearable placeholder="请选择项目状态">
                    <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <div style="padding-left: 40px">
                <el-button type="primary" @click="findPage">查询</el-button>
            </div>

            <div style="padding-left: 30%">
                <el-button type="primary" @click="toAdd">添加项目</el-button>
            </div>
        </div>

        <!--内容-->
        <div style="padding-top: 50px" v-loading="loading">
            <el-table
                    :data="list"
                    border
                    style="width: 100%">
                <el-table-column
                        fixed
                        width="100"
                        prop="projectTitle"
                        label="项目名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="projectType.typeName"
                        label="项目分类"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="projectCycle"
                        label="项目周期(天)"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="projectRate"
                        label="项目利率(%)"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="projectSize"
                        label="项目规模"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="investmentMaxNum"
                        label="最大投资金额"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="maxNum"
                        label="最大投资次数"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="investmentMinNum"
                        label="最小投资金额"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="projectStatus"
                        label="状态"
                        align="center">
                    <template slot-scope="scope">
                        <el-tag effect="dark" :type="scope.row.projectStatus==1?'':'info'">
                            {{scope.row.projectStatus==1?'上架中':scope.row.projectStatus==2?'已结束':'未上架'}}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column
                        align="center"
                        fixed="right"
                        width="250"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showEdit(scope.row.id)">投资详情</el-button>
                        <el-button type="text" @click="toEdit(scope.row.id)">编辑</el-button>
<!--                        <el-button type="text" v-if="scope.row.projectStatus==1" @click="deleteById(scope.row.id)">下架</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <div style="padding-top: 50px;text-align: center">
            <el-pagination
                    class="pagiantion"
                    :total="total"
                    @current-change="handleSelectionChange"
                    :current-page="project.pageIndex"
                    :page-size="project.pageSize"
                    layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProjectList",
        data() {
            return {
                loading:false,
                options: [{
                    id: 1,
                    name: '上架中'
                },
                    {
                        id: 0,
                        name: '未上架'
                    },
                    {
                        id: 2,
                        name: "已结束"
                    }],
                total: 0,
                project: {
                    pageIndex: 1,
                    pageSize: 5,
                    projectTypeId: null,
                    projectStatus: null
                },
                list: [],
                typeList: []
            }
        },
        methods: {
            toEdit(id){
                this.$router.replace('/projectEdit?id='+id)
            },
            showEdit(id){
                this.$router.replace('/projectDetal?id='+id)
            },
            deleteById(id){
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/project/deleteById?id='+id).then(resp=>{
                        if (resp.data.code==200){
                            this.$message.success('操作成功')
                            this.findPage()
                        }
                    })
                })
            },
            findPage() {
                this.loading = true
                this.axios.post('/project/findPage', this.project).then(resp => {
                    if (resp.data.code == 200) {
                        this.list = resp.data.data.data;
                        this.total = resp.data.data.total;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleSelectionChange(currentPage) {
                this.project.pageIndex = currentPage;
                this.findPage();
            },
            toAdd() {
                this.$router.replace('/projectAdd')
            },
            findProjectType() {
                this.loading = true;
                this.axios.get('/project/findTypeAll').then(resp => {
                    if (resp.data.code == 200) {
                        this.typeList = resp.data.data;
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        created() {
            this.findPage()
            this.findProjectType()
        }
    }
</script>

<style scoped>
    .el-select {
        width: 200px;
    }

    .el-input {
        width: 300px;
    }
</style>
